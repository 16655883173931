import React from "react";
import { HiX } from "react-icons/hi";
import classNames from "classnames";

export type CloseButtonProps = {
  className?: string;
  absolute?: boolean;
  defaultStyle?: boolean;
  svgClass?: string;
  onClick?: (e) => void;
};

const CloseButton = React.forwardRef<any, CloseButtonProps>((props, ref) => {
  const { absolute, className, defaultStyle, svgClass, ...rest } = props;
  const closeButtonAbsoluteClass = "absolute z-10";

  const closeButtonClass = classNames(
    "close-btn",
    defaultStyle && "close-btn-default",
    absolute && closeButtonAbsoluteClass,
    className
  );

  return (
    <span className={closeButtonClass} role="button" {...rest} ref={ref}>
      <HiX />
    </span>
  );
});

CloseButton.defaultProps = {
  defaultStyle: true,
};

export default CloseButton;
