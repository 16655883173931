import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";

const rootReducer = combineReducers({
  theme,
  auth,
  base,
});

export default rootReducer;

export type RootStateType = ReturnType<typeof rootReducer>;
