import BaseService from "./BaseService";

const ApiService = {
  async fetchData(param) {
    // return new Promise((resolve, reject) => {
    //     BaseService(param).then(response => {
    //         resolve(response)
    //     }).catch(errors => {
    //         reject(errors)
    //     })
    // })
    const response = await BaseService(param);
    return response;
  },
};

export default ApiService;
