import { theme } from "twin.macro";

export const COLOR_1 = theme`colors.indigo.600`; //twColor.indigo['600']
export const COLOR_2 = theme`colors.blue.500`;
export const COLOR_3 = theme`colors.emerald.500`;
export const COLOR_4 = theme`colors.amber.500`;
export const COLOR_5 = theme`colors.red.500`;
export const COLOR_6 = theme`colors.purple.500`;
export const COLOR_7 = theme`colors.cyan.500`;

export const COLOR_1_LIGHT = theme`colors.indigo.100`;
export const COLOR_2_LIGHT = theme`colors.blue.100`;
export const COLOR_3_LIGHT = theme`colors.emerald.100`;
export const COLOR_4_LIGHT = theme`colors.amber.100`;
export const COLOR_5_LIGHT = theme`colors.red.100`;
export const COLOR_6_LIGHT = theme`colors.purple.100`;
export const COLOR_7_LIGHT = theme`colors.cyan.100`;

export const COLORS = [
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
];

export const COLORS_LIGHT = [
  COLOR_1_LIGHT,
  COLOR_2_LIGHT,
  COLOR_3_LIGHT,
  COLOR_4_LIGHT,
  COLOR_5_LIGHT,
  COLOR_6_LIGHT,
  COLOR_7_LIGHT,
];
