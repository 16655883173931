import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentRouteKey: "",
  products: [] as any[],
};

export const commonSlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { setCurrentRouteKey, setProducts } = commonSlice.actions;

export default commonSlice.reducer;
