import { SIZES } from "components/ui/utils/constant";
import { DIRECTIONS, THEME_ENUM } from "constants/theme.constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
  themeColor: "indigo",
  direction: DIRECTIONS.LTR,
  mode: THEME_ENUM.MODE_LIGHT,
  locale: "en",
  primaryColorLevel: 600,
  cardBordered: true,
  panelExpand: false,
  controlSize: SIZES.MD,
  navMode: THEME_ENUM.NAV_MODE_LIGHT,
  authLayout: {
    type: THEME_ENUM.LAYOUT_TYPE_BLANK,
  },
  layout: {
    type: THEME_ENUM.LAYOUT_TYPE_MODERN,
    sideNavCollapse: true,
    previousType: null,
  },
};
