import { ConfigProvider } from "components/ui";
import useDarkMode from "utils/hooks/useDarkMode";
import { themeConfig } from "configs/theme.config";
import { useAppSelector } from "store";

const Theme = (props: any) => {
  const theme = useAppSelector((state) => state.theme);
  const [isDark] = useDarkMode();

  const currentTheme = {
    ...themeConfig,
    ...theme,
    mode: isDark ? "dark" : "light",
  };

  return <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>;
};

export default Theme;
