import { forwardRef, ReactNode } from "react";
import classNames from "classnames";

export interface ContainerProps {
  className?: string;
  children?: ReactNode;
  asElement?: any;
}

const Container = forwardRef<any, ContainerProps>((props, ref) => {
  const { className, children, asElement: Component = "div", ...rest } = props;

  return (
    <Component
      ref={ref}
      className={classNames("container mx-auto", className)}
      {...rest}
    >
      {children}
    </Component>
  );
});

export default Container;
