import React from "react";

export type MenuContextType = {
  activeKey?: string;
  onSelect?: (key, e) => void;
};
const MenuContext = React.createContext<MenuContextType | null>(null);

export const MenuContextProvider = MenuContext.Provider;

export default MenuContext;
