import React, { forwardRef, ReactNode } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { DIRECTIONS } from "constants/theme.constant";

export interface ScrollBarProps {
  direction?: DIRECTIONS;
  children?: ReactNode;
  autoHide?: boolean;
}

const ScrollBar = forwardRef<any, ScrollBarProps>((props, ref) => {
  const { direction = "ltr", ...rest } = props;

  return (
    <Scrollbars
      ref={ref}
      renderView={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            ...(direction === "rtl" && {
              marginLeft: props.style.marginRight,
              marginRight: 0,
            }),
          }}
        />
      )}
      {...rest}
    />
  );
});

export default ScrollBar;
