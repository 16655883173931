import { createSlice } from "@reduxjs/toolkit";

export interface UserType {
  name: string | null;
  uid: string;
  email: string | null;
  accessToken: string;
  photoUrl: string | null;
  authProvider: string;
  authority: [string];
}

type UserStateType = {
  user: UserType | null;
};

export const initialState: UserStateType = {
  user: null,
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log("setUser action", state, action);
      state.user = action.payload;
    },
    userLoggedOut: () => initialState,
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
