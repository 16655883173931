import React from "react";
import classNames from "classnames";
import useThemeClass from "utils/hooks/useThemeClass";
import { Link } from "react-router-dom";

export interface ActionLinkProps {
  themeColor?: boolean;
  to?: string;
  children: any;
  className?: string;
  href?: string;
}

const ActionLink = (props: ActionLinkProps) => {
  const {
    children,
    className,
    themeColor = true,
    to,
    href = "",
    ...rest
  } = props;

  const { textTheme } = useThemeClass();

  const classNameProps = {
    className: classNames(
      themeColor && textTheme,
      "hover:underline",
      className
    ),
  };

  return to ? (
    <Link to={to} {...classNameProps} {...rest}>
      {children}
    </Link>
  ) : (
    <a href={href} {...classNameProps} {...rest}>
      {children}
    </a>
  );
};

export default ActionLink;
